<template>
  <h4 class="title" :class="cssClasses">
    <slot />
  </h4>
</template>

<script>
export default {
  props: {
    level: {
      type: String,
      required: true,
    },
  },
  computed: {
    cssClasses() {
      return {
        'title--1': this.level === '1',
        'title--3': this.level === '3',
        'title--4': this.level === '4',
        'title--5': this.level === '5',
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.title
  font-weight: 600
  color: var(--primary-color-dark-blue)

.title--1
  font-size: 40px
  line-height: 48px
  margin-bottom: 40px

  @media screen and (max-width: 1199px)
    font-size: 32px
    font-weight: 700
    line-height: 30px
    margin-bottom: 18px

.title--3
  font-size: 48px
  font-weight: bold
  line-height: 48px
  margin: 40px 0 32px

.title--4
  font-size: 40px
  line-height: 48px
  margin: 40px 0 32px

.title--5
  font-size: 32px
  line-height: 32px
  margin: 32px 0 28px
  color: #09A356
</style>
